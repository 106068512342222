<template>
  <div class="pa-8">
    <v-text-field name="form_name" label="Form Name" outlined></v-text-field>

    <div class="build-wrap" v-show="isShowFormBuilder"></div>
    <div id="render-container" v-show="isShowFormRender"></div>

    <!-- <CInput name="form_name" label="Form Name" placeholder="Enter name of form" v-show="isShowFormBuilder" :value.sync="$v.form.formName.$model" invalidFeedback="Required" :isValid="checkIfValid('formName')" /> -->
  </div>
</template>
<style scoped src="@/css/form/edit.css"></style>
<script>
import { validationMixin } from "vuelidate"
import { required } from "vuelidate/lib/validators"

window.$ = window.jQuery = require('jquery');
require('../../assets/jquery_plugin/jquery-ui.min');
require('../../assets/jquery_plugin/jquery_formbuilder_plugin/form-builder.min.js');
require('../../assets/jquery_plugin/jquery_formbuilder_plugin/form-render.min.js');

export default {
  name: 'EditForm',
  data: function () {
    return {
      isShowFormRender: false,
      isShowFormBuilder: true,
      form: this.getEmptyForm(),
      exceptFieldType: ["header", "paragraph"],
      formid: "",
      formData: ""
    }
  },
  mixins: [validationMixin],
  validations:{
    form: {
      formName: {
        required
      }
    }
  },
  mounted: function(){
    this.formid = this.$route.query.formid;
    this.getFormData();
  },
  computed: {
    isValid () { return !this.$v.form.$invalid }
  },
  methods: {
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      } 
      return !(field.$invalid || field.$model === '')
    },
    buildForm: function(){
      var vueObj = this;
      var options = {
        onSave: function(){
          vueObj.saveForm();
        },
        actionButtons: [{
          id: 'preview',
          className: 'btn btn-success',
          label: 'Preview',
          type: 'button',
          events: {
            click: function() {
              vueObj.showFormRender();
            }
          }
        }],
        formData: vueObj.formData
      }
      $(".build-wrap").formBuilder(options);
    },
    showFormRender: function(){
      const formRenderData = $('.build-wrap').formBuilder('getData', "json");
      let objJsonStr = JSON.stringify(formRenderData);
      this.pretext = objJsonStr;
      var encoded = btoa(objJsonStr);
      var decoded = atob(encoded);
      this.pretext2 = decoded;

      $('#render-container').formRender({
        formData: formRenderData,
        dataType: 'json'
      });
      this.isShowFormRender = true;
      this.isShowFormBuilder = false;
    },
    showFormBuilder: function(){
      this.isShowFormRender = false;
      this.isShowFormBuilder = true;
    },
    saveForm: function(){
      var vueObj = this;
      this.$v.$touch();
      if(this.isValid){
        const formRenderData = $('.build-wrap').formBuilder('getData', "json");
        var data = JSON.parse(formRenderData);
        let objJsonStr = JSON.stringify(formRenderData);
        var base64String = btoa(objJsonStr);
        var dataLength = data.length;
        var arrFields = [];
        var objFields = {};
        var arrOpt = [];
        var i;
        for(i = 0; i < dataLength; i++){
          if( !(this.exceptFieldType.includes(data[i].type)) ){
            objFields = {};
            arrOpt = [];
            objFields.type = data[i].type;
            objFields.name = data[i].name;
            objFields.required = data[i].required;
            objFields.default = "";
            if(typeof data[i].values !== "undefined"){
              var j;
              var optLength = data[i].values.length;
              for(j = 0; j < optLength; j++){
                arrOpt.push(data[i].values[j].value);
              }
            }
            objFields.options = arrOpt;
            arrFields.push(objFields);
          }
        }
        var postData = {idform: this.formid, name: this.form["formName"], fields: arrFields, form_string: base64String };
        this.$store.commit('set', ['modalSpinnerText','Updating...']);
        this.$store.commit('toggle', 'showHideModalSpinner');
        this.$http
        .put('/api/form/' + this.formid,
        postData,                 
        {
          headers: {
            Authorization: "Bearer " + window.$cookies.get("requestToken")
          }
        }
        )
        .then(function (res) {
            console.log(res);   
            this.$store.commit('toggle', 'showHideModalSpinner');        
        })
        .catch(function (error) {
            console.log(error);
            vueObj.$store.commit('toggle', 'showHideModalSpinner');    
        });  
      }
    },
    getFormData: function(){
      var vueObj = this;
      this.$store.commit('set', ['modalSpinnerText','Loading...']);
      this.$store.commit('toggle', 'showHideModalSpinner');
      this.$http
      .get('/api/form/' + this.formid,{
        headers: {
          'Authorization': 'Bearer ' + window.$cookies.get("requestToken"),
          'Include-Form-String': 'true'
        }
      })
      .then( response => {
        var decode = atob(response.data.form_string);
        this.formData = JSON.parse(decode);
        this.buildForm();
        this.form["formName"] = response.data.name;
        this.formid = response.data.idform;
        this.$store.commit('toggle', 'showHideModalSpinner');
      })
      .catch(function (error) {
            console.log(error);
            vueObj.$store.commit('toggle', 'showHideModalSpinner');    
        });  
    },
    getEmptyForm () {
      return {
        formName: ""
      }
    }
  }
}
</script>
